<template>
  <div>
		<v-container>
			<navbar title="درباره ما" route='/apps'/>
			<v-main >
				<about/>
			</v-main>
		</v-container>
	</div>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import about from './components/cards/aboutUs'
export default {
	components: {
		navbar,
		about
	}
}
</script>