<template>
  <div>
		<v-card class="pa-3 card">
			<v-row>
				<v-col>
					<span class="large-font primaryText--text font-weight-bold">درباره ما</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div style="font-size: 14px;" class="px-3" v-html="about.full_text_origin">
					</div>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import { get_content } from '@/models/content'
export default {
	computed: {
		...mapState({
		about: state =>state.content
		})
	},
	created () {
		const config = JSON.parse(localStorage.getItem('config')).other_configs
		get_content(JSON.parse(config).about_us_content_id)
		
	}
}
</script>